<script>
	import Base from '@backend/Base.vue';

	export default {
		name: "Legal",
		extends: Base,
		data() {
			return {
				Name: "Legal",
				mrLevel: {},
        dataLength: 0
			}
		},
		mounted() {
			this.$set(this.$root, 'page', this)
			this.refreshData()
      this.moduleRedirect(false)
		},
		watch: {
			'$route.query'() {
				this.refreshData()
			},
			'filter.level'() {
				this.search()
			},
			'filter.search'(){
          this.search()
      },
			'row.as_name'(v) {
				if (v.length > 0) {
					$("[name='as_name']").valid();
				}
			},
			'row.as_val'() {
				let plain = $("<div/>").html(CKEDITOR.instances.as_val.getSnapshot()).text();
				if (plain.length > 0) {
					if (plain.length < 10) {
						$("[error='as_val'] label").text("Input min 10 characters");
					} else {
						$("[error='as_val'] label").text("");
					}
				}
			}
		}
	}
</script>

<template>
	<div class="container-fluid">
		<PageTitle :no-add-item="true"></PageTitle>

		<!-- ======================================================================== 
			LIST DATA
		============================================================================= -->
		<div class="row" v-if="!$route.params.id">
			<div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
				<div class="card">
					<div class="card-body">
						<VForm @resp="search">
							<div class="row gutter-10">
								<div class="col-sm-5">
									<h5 class="card-title">Daftar {{ObjectName}}</h5>
								</div>
								<div v-if="dataLength > 0" class="col-9 col-lg-3 col-sm-4 ml-auto">
									<div class="form-group mb-0">
										<div class="input-group">
											<input type="text" v-model="filter.search" class="form-control" placeholder="Cari...">
											<div class="input-group-append">
												<button class="btn btn-info" type="button" @click="search()">
                          <i class="fas fa-search"></i>
                        </button>
											</div>
										</div>
									</div>
								</div>
								<div v-if="dataLength > 0" class="col-3 col-md-auto">
									<router-link :to="{name:Name}" class="btn btn-warning">Reset</router-link>
								</div>
							</div>
						</VForm>
					</div>
					<div class="table-responsive ajax-table">
						<table class="table table-striped table-bordered">
							<thead>
								<tr>
									<th>#</th>
									<th>
										Judul
									</th>
									<th class="text-center" v-if="moduleRole('Edit')" width="120px">Aksi</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(v,k) in data.data" :key="k">
									<td class="number">{{(data.per_page*(data.current_page-1))+k+1}}</td>
									<td>{{(v.as_name||'-')}}</td>
									<td class="btn-action text-center" v-if="moduleRole('Edit')">
										<router-link v-if="moduleRole('Edit')" class="icon_action" :to="{name:Name,params:{id:v.id}}" v-tooltip="'Ubah'"><i
												class="ti-marker-alt"></i></router-link>
									</td>
								</tr>
								<tr v-if="NotFound">
									<td colspan="99">
										<h5 class="tc">{{NotFound}}</h5>
									</td>
								</tr>
								<tr v-if="data.data===false">
									<td colspan="99">
										<LoadingSpinner light></LoadingSpinner>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
					<div class="card-body">
						<Pagination class="justify-content-end m-0" :data="data" :limit="3" @pagination-change-page="onPaging"></Pagination>
					</div>
				</div>
			</div>
		</div>

		<!-- ======================================================================== 
			FORM CRUD	
		============================================================================= -->
		<div class="card" v-if="$route.params.id">
			<VForm @resp="submitForm" method="post">
				<div class="card-body">
					<div class="row mb-3">
						<div class="col-md-8">
							<h5 class="card-title">{{row.id?"Ubah":"Tambah"}} {{ObjectName}}</h5>
						</div>
					</div>
					<div class="info"></div>
					<div class="row">
						<div class="col-sm-12">
							<BoField 
								name="as_name" 
								v-model="row.as_name" 
								:label="'Judul'" 
								:attr="{
									placeholder: 'e.g. Kebijakan Privasi',
									readonly: 'readonly'
								}"
							></BoField>
							<BoField name="as_val" :label="'Deskripsi'">
								<CKEditor name="as_val" v-model="row.as_val"></CKEditor>
							</BoField>
						</div>
						<div class="col-12">
							<div class="text-right">
                <router-link
                  :to="{name: $route.name}"
                  class="fcbtn btn btn-light btn-outline btn-1e btn-rounded mr-2"
                >
                  Kembali
                </router-link>
								<button type="submit" class="fcbtn btn btn-info btn-outline btn-rounded btn-loading">Ubah</button>
							</div>
						</div>
					</div>
				</div>
			</VForm>
		</div>
	</div>
</template>